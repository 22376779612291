import styles from './ProductPrice.module.scss';
import stylesPLP from '../../objects/productList/PLP.module.scss';
import { memo, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'components/primitives/grid';
import { Placeholder } from 'components/primitives/placeholders';
import { UomSelector, useCanViewUom } from 'components/primitives/product';
import { renderHTML, makeSimpleText } from 'utils/render';
import { useSanaTexts } from 'components/sanaText';
import { getFormatPrice, formatAsPercentage } from 'utils/format';
import { usePriceModel } from 'utils/product';
import { useCurrencyInfo } from 'utils/hooks';
import { requestCalculatedFields } from 'behavior/pages/productList';
import { connect } from 'react-redux';
import { connectToContext } from 'utils/react';
import ListContext from '../../objects/productList/b2b/ListContext';

const ProductPrice = ({ product, salesPrice, basePrice, allowUOMSelection, updateUoms, requestCalculatedFields }) => {
  const canViewUom = useCanViewUom();
  const [uomId, updateUomId] = useState(product.uom && product.uom.id);

  const { uoms, id: productId } = product;
  const showUomSelector = canViewUom && uomId && uoms && uoms.length > 0;
  const updateUom = useCallback(uomId => {
    updateUomId(uomId);
    const options = { ids: [productId], page: { size: 1, index: 0 }, uomId };
    updateUoms(productId, uomId);
    requestCalculatedFields(options);
  }, [productId, updateUoms, requestCalculatedFields]);

  const currencyInfo = useCurrencyInfo();
  const { price, actionPrice, discountAmount, discountPercentage } = usePriceModel(salesPrice, basePrice);
  const formatAsPrice = currencyInfo && getFormatPrice(currencyInfo);

  const formattedPrice = useMemo(() => {
    if (!currencyInfo)
      return null;

    return renderHTML(formatPrice(formatAsPrice(price), currencyInfo));
  }, [currencyInfo, price]);

  const formattedActionPrice = currencyInfo && actionPrice
    && actionPrice !== price && formatAsPrice(actionPrice);

  const { texts: [
    priceLbl,
    actionPriceLbl,
    discountLbl,
    discountAmountTemplate,
    discountPercentTemplate,
    separatorLbl,
  ], loaded } = useSanaTexts([
    'Price',
    'ActionPrice_Label',
    'ActionPrice_Discount_Label',
    'ActionPrice_DiscountAmount',
    'ActionPrice_DiscountPercentage',
    'UnitSeparator',
  ]);

  if (!loaded || formattedPrice == null)
    return <Placeholder className="price-block product-price-placeholder" />;

  return (
    <>
      {(!!(formattedActionPrice || discountAmount || discountPercentage)) && (
        <Col xs="auto" className="price-block price-list" role="group">
          {!!actionPrice && (
            <div className={`${styles.actionPrice} action-price`} title={actionPriceLbl}>
              <i className="visually-hidden">{actionPriceLbl} {currencyInfo.symbol + actionPrice}</i>
              <span aria-hidden suppressHydrationWarning>
                {formattedActionPrice}
              </span>
            </div>
          )}
          {!!discountAmount && (
            <div className={`${styles.discountAmount} discount-amount`} title={discountLbl}>
              <i className="visually-hidden">{discountLbl} {currencyInfo.symbol + discountAmount}</i>
              <span aria-hidden suppressHydrationWarning>
                {makeSimpleText(discountAmountTemplate, [formatAsPrice(discountAmount)])}
              </span>
            </div>
          )}
          {!!discountPercentage && (
            <div className={`${styles.discountPercentage} discount-percentage`} title={discountLbl}>
              <i className="visually-hidden">{discountLbl} {discountPercentage + '%'}</i>
              <span aria-hidden>
                {makeSimpleText(discountPercentTemplate, [formatAsPercentage(discountPercentage, currencyInfo, false, 2)])}
              </span>
            </div>
          )}

        </Col>
      )}
      <Col xs="auto" className="price-block" title={priceLbl}>
        <i className="visually-hidden">{priceLbl} {currencyInfo.symbol + price}</i>
        <span className={`${styles.actualPrice} actual-price`} aria-hidden suppressHydrationWarning>
          {formattedPrice}
        </span>
      </Col>
      <Col xs="auto" className={styles.priceUomCustomWrp}>
        {showUomSelector && (
          <div className={`${stylesPLP.uomSelector} ${stylesPLP.uomSeparator}`}>
            <span className={styles.priceUomSeparator}>{makeSimpleText(separatorLbl)}</span>
            <UomSelector
              productId={productId}
              className={styles.priceUomCustom}
              allowUOMSelection={allowUOMSelection}
              uomId={uomId}
              uoms={uoms}
              onUomChange={updateUom}
              isOrderable
            />
          </div>
        )}
      </Col>
    </>
  );
};

ProductPrice.propTypes = {
  salesPrice: PropTypes.number,
  basePrice: PropTypes.number,
};

const connectedProductPrice = connect(
  ({ settings }) => ({
    allowUOMSelection: settings.product && settings.product.allowUOMSelection,
  }),
  { requestCalculatedFields },
)(memo(props => {
  if (props.salesPrice === null)
    return null;

  if (typeof props.salesPrice === 'undefined')
    return <Placeholder className="price-block product-price-placeholder" />;

  return <ProductPrice {...props} />;
}));

export default connectToContext([ListContext], ({ updateUoms }) => {

  return {
    updateUoms,
  };
})(connectedProductPrice);

function formatPrice(price, currencyInfo) {
  if (currencyInfo.decimalDigits === 0)
    return price;

  return price.replace(/(\d+)([^\d]*)$/, '<sup>$1</sup>$2');
}
