exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ProductPrice_action-price{color:var(--theme-listPrice_Color,#b2b2b2);font-size:var(--theme-listPrice_Size,11px);text-decoration:line-through}.ProductPrice_action-price,.ProductPrice_actual-price,.ProductPrice_discount-amount{white-space:nowrap}.ProductPrice_discount-amount,.ProductPrice_discount-percentage{font-weight:700}.ProductPrice_discount-amount{color:var(--theme-listPrice_DiscountAmount_Color,#cb2245);font-size:var(--theme-listPrice_DiscountAmount_Size,11px)}.ProductPrice_discount-percentage{color:var(--theme-listPrice_DiscountPercentage_Color,#cb2245);font-size:var(--theme-listPrice_DiscountPercentage_Size,11px)}.ProductPrice_actual-price{color:var(--theme-bulkPrice_Color,#333);font-size:var(--theme-bulkPrice_Size,16px)}.ProductPrice_actual-price sup{font-size:50%;top:-.7em}.ProductPrice_price-uom-custom-wrp{font-size:var(--theme-bulkPrice_Size,16px);padding-left:0;padding-right:0}.ProductPrice_price-uom-custom-wrp .ProductPrice_price-uom-custom>span{height:1.5em}.ProductPrice_price-uom-separator{font-size:var(--theme-bulkPrice_Size,16px);margin-right:6px}", ""]);

// exports
exports.locals = {
	"action-price": "ProductPrice_action-price",
	"actionPrice": "ProductPrice_action-price",
	"actual-price": "ProductPrice_actual-price",
	"actualPrice": "ProductPrice_actual-price",
	"discount-amount": "ProductPrice_discount-amount",
	"discountAmount": "ProductPrice_discount-amount",
	"discount-percentage": "ProductPrice_discount-percentage",
	"discountPercentage": "ProductPrice_discount-percentage",
	"price-uom-custom-wrp": "ProductPrice_price-uom-custom-wrp",
	"priceUomCustomWrp": "ProductPrice_price-uom-custom-wrp",
	"price-uom-custom": "ProductPrice_price-uom-custom",
	"priceUomCustom": "ProductPrice_price-uom-custom",
	"price-uom-separator": "ProductPrice_price-uom-separator",
	"priceUomSeparator": "ProductPrice_price-uom-separator"
};